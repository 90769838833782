<template>
    <form class="admin-form" ref="form">
        <h2>
            Add an order
        </h2>

        <ul class="unstyled-list">
            <li>
                <label for="client">
                    Client
                </label>

                <input type="text" v-model="filterUser" placeholder="Narrow your search down..." />

                <select id="client" name="fk_user" v-model="order.fk_user">
                    <option v-for="u in filteredUsers" :key="u.id" :value="u.id">
                        {{ u.name }}
                    </option>
                </select>
            </li>

            <li>
                <label for="images">
                    Select the images
                </label>

                <input type="text" id="images" v-model="filterImage" placeholder="Narrow your search down..." />

                <div class="image-select">
                    <div class="image-option" v-for="i in filteredImages" :key="i.id"
                        :class="order.items.indexOf(i.id) >= 0 ? 'selected' : ''">
                        <input type="checkbox" v-model="order.items" :value="i.id" :id="`img-${i.id}`" />

                        <label :for="`img-${i.id}`">
                            <figure :class="{ deleted: i.deleted == 1}">
                                <img :src="base + i.preview" :alt="i.id" />

                                <figcaption>
                                    {{ i.id }}
                                </figcaption>
                            </figure>
                        </label>
                    </div>
                </div>

                <small>
                    {{ order.items.length }} items selected.
                </small>
            </li>

            <li>
                <label for="status">
                    Order status:
                </label>
                
                <select id="status" name="status" v-model="order.status">
                    <option v-for="s in statuses" :key="s" :value="s">
                        {{ s }}
                    </option>
                </select>
            </li>

            <li>
                <label for="discount">
                    Discount:
                </label>
                <input id="discount" type="number" placeholder="Discount..." v-model="discount" :min="discountMin" />
            </li>

            <li>
                <label for="costs">
                    Total:
                </label>
                <input id="costs" type="number" v-model="order.costs" readonly :data-value="total" />
            </li>

            <li>
                <button @click.prevent="addOrder">
                    Add order
                </button>
            </li>
        </ul>
    </form>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import axios from 'axios';

var vm;

export default {
    data()
    {
        return {
            default: {
                costs: 0,
                fk_user: '',
                items: [],
                status: 'open',
            },
            discount: 0,
            discountPrevOrder: 0,
            filterImage: '',
            filterUser: '',
            order: {
                fk_user: '',
                costs: 0,
                items: [],
                status: 'open',
            },
            statuses: [
                'open',
                'paid',
            ],
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'clients',
            'freeBreakpoint',
            'images',
            'tiers',
            'user',
        ]),


        discountMin()
        {
            let d = parseFloat(vm.discountPrevOrder) + vm.selectionDiscount;

            // If the minimum discount changes, also set the discount to that
            vm.discount = d;

            return d;
        },


        filteredImages()
        {
            let images = [];

            // First, trim down the list of images to only that of the admin user
            for (let i = 0; i < vm.images.length; i++)
            {
                // Get the image
                let img = vm.images[i];

                // If the image is owned by the admin user, add it to the list
                if (vm.user.images.owned.indexOf(img.id) >= 0)
                {
                    images.push(img);
                }
            }

            return vm.filterList(images, 'id', vm.filterImage);
        },


        filteredUsers()
        {
            let users = vm.filterList(vm.clients, 'name', vm.filterUser);

            return users;
        },


        selectedImages()
        {
            let images = [];

            for (let i = 0; i < vm.images.length; i++)
            {
                let img = vm.images[i];

                // If the image is in the current selection, add it to the list
                if (vm.order.items.indexOf(img.id) >= 0)
                {
                    images.push(img);
                }
            }

            return images;
        },


        selectedClient()
        {
            if (vm.order.fk_user === '') return null;

            // Get the selected user
            for (let i = 0; i < vm.clients.length; i++)
            {
                let c = vm.clients[i];

                if (c.id == vm.order.fk_user) return c;
            }

            // If no match was found, return null
            return null;
        },


        selectedClientLicenseFee()
        {
            return vm.selectedClientTier ? parseFloat(vm.selectedClientTier.license_fee) : 0;
        },


        selectedClientTier()
        {
            // If no client is selected, return null
            if (vm.selectedClient === null) return null;

            // Get the selected client's tier
            for (let i = 0; i < vm.tiers.length; i++)
            {
                let t = vm.tiers[i];

                if (t.id == vm.selectedClient.fk_tier) return t;
            }

            // If no match was found, return null
            return null;
        },


        selectionDiscount()
        {
            let d = 0,
                prices = [],
                s = vm.selectedImages;

            // Get all the prices of the images
            for (let i = 0; i < s.length; i++)
            {
                let img = s[i],
                    imgPrice = vm.selectedClientLicenseFee + parseFloat(img.price);

                prices.push(imgPrice);
            }

            // Sort them alphabetically
            prices.sort();

            // Determine the amount of free images
            let free = Math.floor(prices.length / vm.freeBreakpoint);

            // Add the prices of the free images to the discount
            for (let j = 0; j < free; j++)
            {
                d += prices[j];
            }

            // If there's any spare images that have earned a discount, calculate that here
            let spareDiscount = 0,
                spareImages = prices.length - (free * vm.freeBreakpoint);

            if (prices.length < 3) spareImages = 0;

            if (vm.selectedClientTier)
            {
                switch (vm.selectedClientTier.id)
                {
                    case 3:
                        spareDiscount = 20;
                        break;
    
                    case 4:
                        spareDiscount = 35;
                        break;
    
                    case 5:
                        spareDiscount = 65;
                        break;
    
                    case 6:
                        spareDiscount = 100;
                        break;
    
                    case 7:
                        spareDiscount = 150;
                        break;
    
                    default:
                        spareDiscount = 5;
                        break;
                }
    
                d += (spareDiscount * spareImages);
            }

            return d;
        },


        subtotal()
        {
            let total = 0;

            for (let i = 0; i < vm.images.length; i++)
            {
                let img = vm.images[i];

                if (vm.order.items.indexOf(img.id) >= 0) total += (parseFloat(img.price) + vm.selectedClientLicenseFee);
            }

            return total;
        },


        total()
        {
            let t = vm.subtotal - vm.discount;
            t = t > 0 ? t : 0;
            
            vm.order.costs = t;

            return t;
        },
    },
    

    methods: {
        ...mapActions([
            'getSales',
        ]),


        async addOrder()
        {
            vm.$emit('processing', true);

            try {
                let formData = new FormData();
                
                for (let key in vm.order)
                {
                    formData.append(key, vm.order[key]);
                }
                
                formData.append('return', 'all');

                const response = await axios.post('api/order/create', formData);

                if (response.data === false)
                {
                    vm.$emit('processing', false);
                    
                    alert('An error occurred, while trying to create a new order.');
                }
                else
                {
                    // Reset the form
                    vm.order = vm.default;

                    // Get the new list of orders
                    vm.getSales().then(() => {
                        vm.$emit('processing', false);
                    })
                }
            } catch (error) {
                console.log(error);
            }
        },


        async getClientDiscount(id)
        {
            vm.$emit('processing', true);

            try {
                const response = await axios.get('api/user/' + id + '/discount');

                // Get the new list of clients
                vm.discount = response.data;
                vm.discountPrevOrder = response.data;

                vm.$emit('processing', false);
            } catch (error) {
                console.log(error);
            }
        },


        filterList(list, field, search)
        {
            let filtered = [];

            // If there is no list, return an empty array
            if (!list) return [];

            // Loop through all items
            for (let i = 0; i < list.length; i++)
            {
                let item = list[i],
                    check = item[field].toLowerCase();
                
                if (check.indexOf(search.toLowerCase()) >= 0)
                {
                    filtered.push(item);
                }
            }

            return filtered;
        },
    },


    created()
    {
        vm = this;
    },


    watch: {
        'order.fk_user': function (to)
        {
            if (to !== '')
            {
                // If a client is selected, get it's owed discount from their previous order
                vm.getClientDiscount(to);
            }
        },
    }
}
</script>